import { useState } from "react";
import { useLocation, useParams, useNavigate, Link } from "react-router-dom";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Radio from "@mui/material/Radio";
import Tooltip from "@mui/material/Tooltip";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PreviewIcon from "@mui/icons-material/Preview";
import CustomFooter from "components/LVCustomFooter";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FintechModal from "modals/viewFintechModal";
import MDButton from "components/MDButton";

function LVPeminjamKycDataGrid(props) {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
  const [fintechModal, setFintechModal] = useState(false);
  const [relationId, setRelationId] = useState(0);

  const handleOpenModal = (rowId) => {
    setRelationId(rowId);
    setFintechModal(true);
  };

  const columns = [
    {
      field: "id",
    },
    {
      field: "no",
      headerName: "No.",
      headerAlign: "center",
      align: "center",
      width: 50,
      sortable: false,
    },
    {
      field: "name",
      headerName: "Nama Peminjam",
      headerAlign: "center",
      align: "left",
      renderCell: (params) => (
        <Link to={`/detailkycpeminjam/${encodeURIComponent(params.row.id)}/${params.row.status}`}>
          <span>{params.value}</span>
        </Link>
      ),
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 150,
    },
    {
      field: "statusDokumen",
      headerName: "Status Dokumen",
      headerAlign: "center",
      align: "left",
      renderCell: (params) => {
        const dokNum = Number(params.row.document_count) + Number(params.row.data_count);
        return (
          <Tooltip title={params.value ? params.value : ""}>
            <span>{`${params.value} (${dokNum}/23 Dokumen Terisi)`} </span>
          </Tooltip>
        );
      },

      width: 230,
      sortable: false,
    },
    {
      field: "dataCount",
      headerName: "Terhubung ke Pendana",
      headerAlign: "center",
      align: "left",
      renderCell: (params) => {
        const dataNum = Number(params.row.fintech_count);
        return (
          <Tooltip title={params.value ? params.value : ""}>
            <MDButton onClick={() => handleOpenModal(params.row.company_id)} display="flex">
              <MDTypography variant="body3" color="info">
                {dataNum}
              </MDTypography>
              <MDTypography ml={2} variant="body3" color="">
                Pendana
              </MDTypography>
            </MDButton>
          </Tooltip>
        );
      },

      width: 230,
      sortable: false,
    },
  ];

  const rows = [
    { id: 1, no: 1, name: "PT Maju Jaya", status: "Full Submission" },
    { id: 2, no: 2, name: "PT Transporter Satu", status: "Partial Submission" },
  ];

  const newdata = {
    columns,
    rows: props.rows,
  };

  const renderSX = () => {
    if (props.flat) {
      return {
        bgcolor: "rgba(255, 255, 255, 1)",
        borderRadius: "16px",
        fontSize: "small",
      };
    }
    return {
      bgcolor: "rgba(255, 255, 255, 1)",
      borderRadius: "16px",
      fontSize: "small",
      boxShadow: "10px 15px 27px -28px #1A73E8",
    };
  };

  const sortingHandler = (params) => {
    if (params == []) {
      return;
    }
    props.orderBy(params[0].field);
    props.order(params[0].sort);
  };

  function CustomFooterProps() {
    return (
      <CustomFooter
        page={props.totalPage}
        selectPage={props.selectPage}
        nextPage={props.nextPage}
        prevPage={props.prevPage}
        currentPage={props.currentPage}
        firstPage={props.firstPage}
        lastPage={props.lastPage}
      />
    );
  }

  return (
    <MDBox
      mt={5}
      sx={{
        height: props.height,
        width: "100%",
      }}
    >
      <DataGrid
        {...newdata}
        columns={columns}
        rows={props.rows}
        pageSize={props.pageSize}
        rowsPerPageOptions={props.rowsPerPageOptions}

        editMode="row"
        checkboxSelection={props.checkboxSelection}
        localeText={{ noRowsLabel: "Belum ada data calon peminjam." }}
        disableSelectionOnClick
        sx={renderSX()}
        
        sortingMode="server"
        onSortModelChange={sortingHandler}

        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          newModel.id = false;
          setColumnVisibilityModel(newModel);
        }}
        experimentalFeatures={{ newEditingApi: true }}
        hideFooterPagination
        components={{ Footer: CustomFooterProps }}
      />
      <FintechModal
        open={fintechModal}
        onClose={() => setFintechModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        relationId={relationId}
        viewOnly
      />
    </MDBox>
  );
}

export default LVPeminjamKycDataGrid;
