import Modal from "@mui/material/Modal";
import SelectFintech from "layouts/selectFintech";

function FintechModal(props) {
  return (
    <Modal {...props}>
      <div>
        <SelectFintech
          onClose={props.onClose}
          kyc={props.kyc}
          onAjukan={props.onSubmit}
          id={props.id}
          relationId={props.relationId}
          kyc={props.kyc}
          viewOnly={props.viewOnly}
        />
      </div>
    </Modal>
  );
}

export default FintechModal;
