/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Modal,
} from "@mui/material";
import MDButton from "components/MDButton";
import CustomerModal from "modals/newCustomerModal";
import PartnerHistory from "layouts/editPartnerHistory";
import RiwayatModal from "modals/viewRiwayatModal";
import FintechModal from "modals/viewFintechModal";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchKYCDocs } from "actions/companyAction";
import { transformKycDocsArr } from "common/Util";
import LVKycDocument from "components/LVKycDocument";
import {
  fetchCustomerDetail,
  adminFetchKYCDocs,
  adminFetchCustomerDetail,
} from "actions/kycPeminjamAction";

import { fetchRiwayatKerjasama } from "actions/riwayatKerjasamaAction";
import { invoiceHistory } from "actions/userInvoice";
import { emptyMessage } from "actions";
import CustomFooter from "components/LVCustomFooter";
import LVDocumentPreviewModal from "components/LVPreviewModal";

function LVDetailPengguna(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
  const [id, setId] = useState(false);
  const [relationId, setRelationId] = useState(false);
  const [name, setName] = useState(false);
  const [npwp, setNPWP] = useState(false);
  const [riwayatModal, setRiwayatModal] = useState(false);
  const [fintechModal, setFintechModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [previewModal, setPreviewModal] = useState(false);
  const [previewPath, setPreviewPath] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const listKYCDocsArray = useSelector((state) => state.listKYCDocsArray, shallowEqual);

  const handleOpen = (e) => {
    setId(e.id);
    setRelationId(e.relationId);
    setName(e.label);
    setNPWP(e.npwp);
    setRiwayatModal(true);
  };

  const openDocumentModal = (name, path) => {
    setPreviewTitle(name);
    setPreviewPath(path);
    setPreviewModal(true);
  }

  const closeDocumentModal = () => {
    setPreviewPath("");
    setPreviewModal(false);
  }

  const columnsRiwayat = [
    {
      field: "id",
    },
    {
      field: "no",
      headerName: "No.",
      headerAlign: "center",
      align: "center",
      width: 80,
      renderCell: (params) => <span>{params.api.getRowIndex(params.row.id) + 1}</span>,
    },
    {
      field: "idInvoice",
      headerName: "Nomor Invoice",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => <span>{params.value}</span>,
      width: 150,
    },
    {
      field: "idOrderPenjualan",
      headerName: "ID Order Penjualan",
      headerAlign: "left",
      align: "center",
      renderCell: (params) => <span>{params.value}</span>,
      width: 150,
    },
    {
      field: "customer",
      headerName: "Pelanggan",
      headerAlign: "left",
      align: "left",
      width: 120,
    },
    {
      field: "invoiceStatus",
      headerName: "Status Invoice",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => <span>{params.value}</span>,
      width: 120,
    },
    {
      field: "documentStatus",
      headerName: "Status Dokumen",
      headerAlign: "left",
      align: "left",
      width: 120,
    },
    {
      field: "financingStatus",
      headerName: "Status Pencairan Invoice",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => <span>{params.value}</span>,
      width: 180,
    },
    {
      field: "createdAt",
      headerName: "Terbit Invoice",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => <span>{params.value}</span>,
      width: 130,
    },
    {
      field: "rangeDate",
      headerName: "Jangka Waktu",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => <span>{params.value}</span>,
      width: 130,
    },
  ];

  const columnsPartner = [
    {
      field: "id",
    },
    {
      field: "no",
      headerName: "No.",
      headerAlign: "center",
      align: "center",
      width: 80,
      renderCell: (params) => <span>{params.api.getRowIndex(params.row.id) + 1}</span>,
    },
    {
      field: "npwp",
      headerName: "NPWP",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 250,
    },
    {
      field: "label",
      headerName: "Nama Pelanggan",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 200,
    },
    {
      field: "type",
      headerName: "Badan Usaha",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 150,
    },
    {
      field: "picName",
      headerName: "Nama PIC",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 180,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Aksi",
      sortable: false,
      align: "center",
      width: 180,
      getActions: (params) => [
        <MDBox>
          <MDButton
            onClick={() => handleOpen(params.row)}
            sx={{ color: "#0D6ECD", fontSize: "12px", fontWeight: "bold" }}
            size="small"
          >
            Riwayat Kerjasama
          </MDButton>
        </MDBox>,
      ],
    },
  ];

  const anCompanyRelation = useSelector((state) => state.anCompanyRelation, shallowEqual);
  const anCompanyRelationPagination = useSelector((state) => state.anCompanyRelationPagination, shallowEqual);
  const anUserInvoiceHistory = useSelector((state) => state.anUserInvoiceHistory, shallowEqual);
  const anUserInvoiceHistoryPagination = useSelector((state) => state.anUserInvoiceHistoryPagination, shallowEqual);
  const message = useSelector((state) => state.message, shallowEqual);
  const [type, setType] = useState("invoice");
  const [columns, setColumns] = useState(columnsRiwayat);
  const [page, setPage] = useState(1);

  const handleAjukan = () => {
    setRiwayatModal(false);
    setFintechModal(true);
  };

  useEffect(() => {
    setPage(1);
  }, [type]);

  useEffect(() => {
    const id = decodeURIComponent(params.id);
    if (type === "invoice") {
      dispatch(invoiceHistory({ invoicingId: id, page }));
      setColumns(columnsRiwayat);
    }
    if (type === "kyc") {
      dispatch(adminFetchKYCDocs(id));
    }
    if (type === "kerjasama") {
      setColumns(columnsPartner);
      dispatch(fetchRiwayatKerjasama({ id, page }));
    }
  }, [type, page]);

  const onApprove = ({ id, name }) => {
    setSelected({ id, name });
    setOpenApproveDialog(true);
  };

  const onReject = ({ id, name }) => {
    setSelected({ id, name });
    setOpenRejectDialog(true);
  };

  useEffect(() => {
    const id = decodeURIComponent(params.id);
    dispatch(emptyMessage());
    switch (message.status) {
      case "SUCCESS_CREATE_CUSTOMER_RELATION":
        setRiwayatModal(false);
        setFintechModal(false);
        setOpenDialog(true);
        dispatch(fetchRiwayatKerjasama(id));
        return;
      default:
        return;
    }
  }, [message]);

  function CustomFooterPropsInv() {
    return (
      <CustomFooter
        page={anUserInvoiceHistoryPagination.total_pages}
        selectPage={(i) => setPage(parseInt(i.target.textContent))}
        lastPage={() => setPage(anUserInvoiceHistoryPagination.total_pages)}
        nextPage={() => setPage(anUserInvoiceHistoryPagination.next_page)}
        prevPage={() => setPage(anUserInvoiceHistoryPagination.prev_page)}
        firstPage={() => setPage(1)}
        currentPage={page}
      />
    );
  }

  function CustomFooterPropsRelation() {
    return (
      <CustomFooter
        page={anCompanyRelationPagination.total_pages}
        selectPage={(i) => setPage(parseInt(i.target.textContent))}
        lastPage={() => setPage(anCompanyRelationPagination.total_pages)}
        nextPage={() => setPage(anCompanyRelationPagination.next_page)}
        prevPage={() => setPage(anCompanyRelationPagination.prev_page)}
        firstPage={() => setPage(1)}
        currentPage={page}
      />
    );
  }

  return (
    <>
      <Modal
        open={previewModal}
        onClose={closeDocumentModal}
      // aria-labelledby="modal-modal-title"
      // aria-describedby="modal-modal-description"
      >
        <Card sx={{
          margin: "auto",
          marginTop: 5, marginBottom: 3, width: '100%', maxWidth: "1200px", display: "flex",
        }}>
          <MDBox p={3} mx={3} display="flex" justifyContent="center" />
          <MDBox pb={4} px={2} textAlign="left" lineHeight={1.25} justifyContent="center" >
            <MDTypography mb={2} variant="h4" fontWeight="medium" textTransform="capitalize">
              {previewTitle}
            </MDTypography>
            <embed src={previewPath} width="100%" height="600px" />
          </MDBox>
        </Card>
      </Modal >
      <MDBox
        mt={3}
        sx={{
          height: 400,
          width: "100%",
          position: "relative",
        }}
      >
        <MDTypography variant="h4" sx={{ ml: 2, mb: 2, textTransform: "capitalize" }}>
          {params.name.toLowerCase()}
        </MDTypography>
        <MDBox
          sx={{
            display: "flex",
            py: 2.5,
            px: 2,
            mb: 2,
            bgcolor: "rgba(255, 255, 255, 1)",
            borderRadius: "16px",
            border: "1px solid #e1e1e1",
            fontSize: "small",
            boxShadow: "10px 15px 27px -28px #1A73E8",
          }}
        >
          <MDBox onClick={() => setType("invoice")} sx={{ cursor: "pointer" }}>
            <MDTypography
              variant="body3"
              sx={{
                mr: 4,
                pb: 0.5,
                fontWeight: "bold",
                borderBottom: type === "invoice" ? "3px solid #0D6ECD" : "3px solid #ffffff",
              }}
            >
              Riwayat Invoice
            </MDTypography>
          </MDBox>
          <MDBox onClick={() => setType("kyc")} sx={{ cursor: "pointer" }}>
            <MDTypography
              variant="body3"
              sx={{
                mr: 4,
                pb: 0.5,
                fontWeight: "bold",
                borderBottom: type === "kyc" ? "3px solid #0D6ECD" : "3px solid #ffffff",
              }}
            >
              KYC
            </MDTypography>
          </MDBox>
          {params.type === "broker" ? (
            <MDBox onClick={() => setType("kerjasama")} sx={{ cursor: "pointer" }}>
              <MDTypography
                variant="body3"
                sx={{
                  mr: 4,
                  pb: 0.5,
                  fontWeight: "bold",
                  borderBottom: type === "kerjasama" ? "3px solid #0D6ECD" : "3px solid #ffffff",
                }}
              >
                Dokumen Riwayat Kerjasama
              </MDTypography>
            </MDBox>
          ) : null}
        </MDBox>
        {type === "kyc" ? (
          <LVKycDocument
            mt={0}
            rows={transformKycDocsArr(listKYCDocsArray)}
            onApprove={onApprove}
            onReject={onReject}
            onPreview={(name, path) => openDocumentModal(name, path)}

          />
        ) : null}

        {type === "invoice" ? (
          <DataGrid
            columns={columns}
            rows={anUserInvoiceHistory}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            localeText={{ noRowsLabel: "Belum ada pengguna." }}
            sx={{
              bgcolor: "rgba(255, 255, 255, 1)",
              borderRadius: "16px",
              fontSize: "small",
              boxShadow: "10px 15px 27px -28px #1A73E8",
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => {
              newModel.id = false;
              // this.setState({ columnVisibilityModel: newModel });
              setColumnVisibilityModel(newModel);
            }}
            experimentalFeatures={{ newEditingApi: true }}
            components={{ Footer: CustomFooterPropsInv }}
          />
        ) : null}

        {type === "kerjasama" ? (
          <DataGrid
            columns={columns}
            rows={anCompanyRelation}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            localeText={{ noRowsLabel: "Belum ada pengguna." }}
            sx={{
              bgcolor: "rgba(255, 255, 255, 1)",
              borderRadius: "16px",
              fontSize: "small",
              boxShadow: "10px 15px 27px -28px #1A73E8",
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => {
              newModel.id = false;
              // this.setState({ columnVisibilityModel: newModel });
              setColumnVisibilityModel(newModel);
            }}
            experimentalFeatures={{ newEditingApi: true }}
            components={{ Footer: CustomFooterPropsRelation }}
          />
        ) : null}
      </MDBox>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Berhasil!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Kerjasama telah berhasil dihubungkan
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton
            onClick={() => setOpenDialog(false)}
            variant="contained"
            color="info"
            size="small"
          >
            OK
          </MDButton>
        </DialogActions>
      </Dialog>
      <RiwayatModal
        open={riwayatModal === true}
        onClose={() => setRiwayatModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        admin
        id={id}
        relationId={relationId}
        npwp={npwp}
        name={name}
        onAjukan={handleAjukan}
      />
      <FintechModal
        open={fintechModal === true}
        onClose={() => setFintechModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        id={id}
        relationId={relationId}
      />
    </>
  );
}

export default LVDetailPengguna;
