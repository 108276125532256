import { useState, useEffect, } from "react";
import { DataGrid, } from "@mui/x-data-grid";
import Tooltip from '@mui/material/Tooltip';
import MDTypography from "components/MDTypography";

import MDBox from "components/MDBox";
import MDButton from 'components/MDButton';

const LVKycDocument = (props) => {
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    useEffect(() => {
        if (props.businessType === "fintech") {
            setColumnVisibilityModel({
                ...columnVisibilityModel, required: false, actions: false,
            })
        }
    }, []);


    const columns = [
        {
            field: 'id',
        },
        {
            field: 'no',
            headerName: 'No.',
            headerAlign: 'center',
            align: "center",
            width: 80,
            renderCell: (params) => (
                <span>{params.api.getRowIndex(params.row.id) + 1}</span>
            ),
        },
        {
            field: 'name',
            headerName: 'Nama Dokumen',
            headerAlign: 'center',
            align: "left",
            renderCell: (params) => (
                <Tooltip title={params.value ? params.value : ''} >
                    <span>{params.value}</span>
                </Tooltip>
            ),
            width: 250,
        },
        {
            field: 'status',
            headerName: 'Status',
            headerAlign: 'center',
            align: "left",
            renderCell: (params) => {
                let status = params.value ? params.value : "Belum Diunggah";
                return (
                    <Tooltip title={status} >
                        <span>{status}</span>
                    </Tooltip>
                )
            },
            width: 150,
        },
        {
            field: 'data',
            headerName: 'Data',
            headerAlign: 'center',
            align: "left",
            renderCell: (params) => {
                const pattern = /^http(s)*:\/\/[^\/]+([\/]{0,1}|\/(.+))$/g;
                let matchPattern = pattern.exec(params.value);

                let path = "";
                if (matchPattern !== null && matchPattern[3] !== undefined) {
                    path = matchPattern[2];
                }

                if (params.value === "") {
                    path = "";
                }


                if (params.row.type === 'phone') {
                    return (
                        <Tooltip title={params.value ? params.value : ''} >
                            <span>{params.value}</span>
                        </Tooltip>
                    );
                } else if (path === "") {
                    return <></>
                }
                else {
                    return (

                        <MDBox display="flex" sx={{ justifyContent: "center" }}>
                            <MDButton
                                sx={{ color: "#495057", fontSize: "13px", fontWeight: "bold" }}
                                onClick={() => {
                                    window.open(params.value, '_blank');
                                }}
                            >
                                UNDUH
                            </MDButton>
                            <MDButton

                                sx={{ color: "#495057", fontSize: "13px", fontWeight: "bold", ml: 2 }}
                                onClick={() => props.onPreview(params.row.name, params.value)}
                            >
                                TINJAU
                            </MDButton>
                        </MDBox>
                    );
                }
            }
            ,
            width: 300,
        },
        {
            field: "required",
            headerName: "Wajib",
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                if (params.row.required === true) {
                    return (
                        <span>Ya</span>
                    );
                }
                return (
                    <span>Tidak</span>
                );
            },
            width: 150,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Tindakan',
            sortable: false,
            align: "center",
            width: 180,
            getActions: (params) => {
                if (params.row.status === 'Belum Disetujui') {
                    return [
                        <MDBox display="flex">
                            <MDButton
                                sx={{ color: "#49a3f1", fontSize: "12px", fontWeight: "bold" }}
                                onClick={() => onClickApprove({ id: params.row.id, name: params.row.name })}
                                size="small"
                            >
                                Setujui
                            </MDButton>
                            <MDButton
                                sx={{ color: "#EF5350", fontSize: "12px", fontWeight: "bold" }}
                                onClick={() => onClickReject({ id: params.row.id, name: params.row.name })}
                                size="small"
                            >
                                Tolak
                            </MDButton>
                        </MDBox>
                    ];
                } else {
                    return [];
                }

            }
            ,
        },
    ];

    const rows = [
        { id: 1, no: 1, name: 'No Telepon', status: 'Full Submission' },
        { id: 2, no: 2, name: 'KTP Direktur', status: 'Partial Submission' },
    ];



    const onClickApprove = (id, name) => {
        props.onApprove(id, name);
    }

    const onClickReject = (params) => {
        props.onReject(params);
    }

    const renderSX = () => {
        if (props.flat) {
            return {
                bgcolor: 'rgba(255, 255, 255, 1)',
                borderRadius: '16px',
                fontSize: 'small',
            }
        } else {
            return {
                bgcolor: 'rgba(255, 255, 255, 1)',
                borderRadius: '16px',
                fontSize: 'small',
                boxShadow: '10px 15px 27px -28px #1A73E8',
            }
        }
    }

    return (

        <MDBox
            mt={5}
            sx={{
                width: '100%',
            }}

        >
            <DataGrid
                columns={columns}
                rows={props.rows}
                pageSize={10}
                autoHeight
                rowsPerPageOptions={[10]}
                disableSelectionOnClick
                sx={renderSX()}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => {
                    newModel.id = false;
                    setColumnVisibilityModel(newModel);
                }
                }

                experimentalFeatures={{ newEditingApi: true }}
            />
        </MDBox>
    );
}

export default LVKycDocument;