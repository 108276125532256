/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import Paper from "@mui/material/Paper";
// import { Avatar, Drawer, List, Stack, Toolbar } from "@mui/material";
// import assets from "../../assets";
import appRoutes from "appRoutes";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import colorConfigs from "./styles/colorConfigs";
import sizeConfigs from "./styles/sizeConfigs";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";
import SidebarRoot from "./SidebarRoot";

// Material Dashboard 2 React components
import { initState } from "./action";
import { getCompany } from "actions/companyAction";

// Material Dashboard 2 React context

function Sidebar({ color, brand, brandName, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  const dispatchState = useDispatch();

  const [companyName, setCompanyName] = useState("");
  const [companyType, setCompanyType] = useState("");
  const anCompany = useSelector((state) => state.anCompany, shallowEqual);

  useEffect(() => {
    dispatchState(getCompany());
  }, []);

  useEffect(() => {
    if (anCompany) {
      setCompanyName(anCompany.name);
      setCompanyType(anCompany.type);
    }
  }, [anCompany]);

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  const sidebarState = useSelector((state) => state.sidebarState, shallowEqual);

  useEffect(
    () => () => {
      dispatchState(initState());
    },
    []
  );
  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const user = JSON.parse(localStorage.getItem("user"));
  const role = user?.role;

  const renderRoutes = appRoutes.map((route, index) => {

    let rightRole = false;
    let rightBusiness = false;
    if (user) {
      user.role?.map((item) => {
        if (route.role.includes(item)) {
          rightRole = true;
        }
      });
      if (user.company) {
        if (user.company.business_type) {
          route.business.map((item) => {
            if (user.company.business_type === item) {
              rightBusiness = true;
            }
          });
        }
      }
    }

    if (rightRole && rightBusiness) {
      if (route.sidebarProps) {
        if (route.child) {
          return <SidebarItemCollapse item={route} key={index} />;
        }
        return <SidebarItem item={route} key={index} active={route.state === sidebarState} />;
      }
      return null;
    }
    return null;
  });

  const appName = () => {
    let appNameTmp = "";

    if (user) {
      if (user.company) {
        switch (user?.company?.business_type) {
          case "borrower":
            appNameTmp = "Borrower App";
            break;
          case "broker":
            appNameTmp = "Contractor App";
            break;
          case "vendor":
            appNameTmp = "Supplier App";
            break;
          case "fintech":
            appNameTmp = "Capital Partner App";
            break;
          case "partner":
            appNameTmp = "Partner App";
            break;
          case "kentara":
            appNameTmp = "Internal App";
            break;
          default:
            break;
        }
      }
    }

    return appNameTmp;
  };

  return (
    <SidebarRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      {/* <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox display="flex" alignItems="center" justifyContent="center" alignText="center">
          <a href="/">
            <Paper sx={{ width: 60, height: 60 }}>
              <MDBox
                component="img"
                src={brand}
                alt="Brand"
                width="2rem"
                sx={{ marginLeft: "auto", marginRight: "auto", marginTop: 1 }}
              />
            </Paper>
          </a>
        </MDBox>
      </MDBox>
      <MDTypography variant="h6" color="dark" textAlign="center">
        {appName()}
      </MDTypography> */}
      <MDBox display="flex" flexDirection="column" height="100%" justifyContent="space-between">
        <MDBox>
          <MDBox pt={5} pb={1} pl={3} sx={{ cursor: "pointer" }}>
            <a
              href={
                role
                  ? role.includes("admin")
                    ? "/kycpeminjam"
                    : role.includes("lender")
                      ? "/pengajuanfin"
                      : "/"
                  : "/"
              }
            >
              <img src="/kentara-logo.svg" alt="kentara" />
            </a>
          </MDBox>
          <Divider
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
          <List>{renderRoutes}</List>
        </MDBox>
        <MDBox pl={3} pb={5} pt={5}>
          <>
            <img src="/buildings.svg" alt="" />
            <MDTypography variant="body2" fontWeight="light" sx={{ fontSize: "12px" }}>
              {companyType === "PT" ? "Perseroan Terbatas" : companyType}
            </MDTypography>
            <MDTypography color="black" fontWeight="bold" sx={{ fontSize: "12px" }}>
              {companyName}
            </MDTypography>
          </>
        </MDBox>
      </MDBox>
    </SidebarRoot>
    // <Drawer
    //   variant="permanent"
    //   sx={{
    //     width: sizeConfigs.sidebar.width,
    //     flexShrink: 0,
    //     "& .MuiDrawer-paper": {
    //       width: sizeConfigs.sidebar.width,
    //       boxSizing: "border-box",
    //       borderRight: "0px",
    //       backgroundColor: colorConfigs.sidebar.bg,
    //       color: colorConfigs.sidebar.color
    //     }
    //   }}
    // >
    //   <List disablePadding>
    //     <Toolbar sx={{ marginBottom: "20px" }}>
    //       <Stack sx={{ width: "100%" }} direction="row" justifyContent="center">
    //         {/* <Avatar src={assets.images.logo} /> */}
    //       </Stack>
    //     </Toolbar>
    //     {appRoutes.map((route, index) =>
    //       route.sidebarProps ? (
    //         route.child ? (
    //           <SidebarItemCollapse item={route} key={index} />
    //         ) : (
    //           <SidebarItem item={route} key={index} />
    //         )
    //       ) : null
    //     )}
    //   </List>
    // </Drawer>
  );
}

export default Sidebar;
